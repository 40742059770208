<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Nova Aplicação">
      <form-exam-application action="add"></form-exam-application>
    </base-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    page: {
      title: 'Nova Aplicação'
    },
    exam: {}
  }),
  methods: {
    save () {
      // TODO: save exam application here and redirect to exam application view
    },
    getExam () {
      const organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
      this.$axios.get(`organizations/${organizationId}/exams/${this.$route.params.id}`)
        .then(res => {
          this.exam = res.data
        })
        .catch(async () => {
          const msg = 'Erro na conexão, tente novamente'
          await this.$store.dispatch('alert', { color: 'red', msg })
        })
        .finally(() => {
          // TODO: Implement loading
        })
    }
  },
  computed: {
    breadcrumbs () {
      return [
        {
          text: 'Início',
          disabled: false,
          to: '/'
        },
        {
          text: 'Avaliações',
          disabled: false,
          to: '/exam/list'
        },
        {
          text: this.exam?.data?.name ?? 'Avaliação',
          disabled: false,
          to: `/exam/view/${this.$route.params.id}`
        },
        {
          text: 'Nova Aplicação',
          disabled: false,
          to: '/exam/application'
        }
      ]
    }
  },
  created () {
    this.getExam()
  },
  mounted () {
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
      easing: 'easeInOutCubic'
    })
  }
}
</script>
